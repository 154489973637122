import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { Data, Route } from '@node_modules/@angular/router/src/config';
import { Observable } from '@node_modules/rxjs/internal/Observable';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService
    ) { }

    canActivateInternal(data: Data, state: RouterStateSnapshot): boolean {

        const isTermsandCondtionsApproved = abp.utils.getCookieValue(AppConsts.AddtionalData.isTermsandCondtionsApproved);
        const isAgreementApproved = abp.utils.getCookieValue(AppConsts.ExtraData.isAgreementApproved);
        const isUserHasAgreement = abp.utils.getCookieValue(AppConsts.ExtraData.isUserHasAgreement);
        if (state != null) {
            if (isTermsandCondtionsApproved == null || isTermsandCondtionsApproved == 'false') {
                if (state.url != '/app/main/mazadAdmin/terms') {
                    this._router.navigate(['/app/main/mazadAdmin/terms']);
                    return false;
                }
            } 
            else if ((isUserHasAgreement == 'true') && (isAgreementApproved == null || isAgreementApproved == 'false')) {
                if (state.url != '/app/main/mazadAdmin/agreement') {
                    this._router.navigate(['/app/main/mazadAdmin/agreement']);
                    return false;
                }
            }
        }

        if (UrlHelper.isInstallUrl(location.href)) {
            return true;
        }

        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!data || !data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(data['permission'])) {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }

        // if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
        //     return '/app/admin/hostDashboard';
        // }

        if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
            return '/app/main/dashboard';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }

        return '/app/notifications';
    }
}
