export class AppConsts {

    static readonly encryptKeyForAppSettings = 'Mazad!@#$19827$A';
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';
    static IBANPattern: string = "[A-Za-z0-9]{0,24}";

    static remoteServiceBaseUrl: string;
    static remoteBrodCastBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static remoteNotificationBrodCastBaseUrl: string;
    static captchaUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;
    // elastic RUM
    static elasticRumUrl: string;
    static serviceName: string;
    static serviceVersion: string;
    static active: string;
    static instrument: string;
    static disableInstrumentations: string;
    static environment: string;
    static logLevel: string;
    static apiVersion: string;
    static breakdownMetrics: string;
    static flushInterval: string;
    static pageLoadTraceId: string;
    static pageLoadSampled: string;
    static pageLoadSpanId: string;
    static pageLoadTransactionName: string;
    static distributedTracing: string;
    static distributedTracingOrigins: string;
    static propagateTracestate: string;
    static EnableRum: string;
    static appSupportAutoBidding: string;
    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Mazad'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly AddtionalData = {
        isTermsandCondtionsApproved: 'is_terms_and_condtions_approved'
    };

    static readonly ExtraData = {
        isAgreementApproved: 'is_agreement_approved',
        isUserHasAgreement:'is_user_has_agreement'
    };


    static readonly grid = {
        defaultPageSize: 10
    };
}
