import { Component, Input } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import * as _ from 'lodash';

class ErrorDef {
    error: string;
    localizationKey: string;
    errorProperty: string;
}

@Component({
    selector: '<validation-messages>',
    template: `<div class="has-danger" style="margin-left:115px;" *ngIf="formCtrl?.invalid && (formCtrl?.dirty || formCtrl?.touched)">
                    <div *ngFor="let errorDef of errorDefsInternal">
                        <div *ngIf="getErrorDefinitionIsInValid(errorDef)" class="form-control-feedback">
                            {{getErrorDefinitionMessage(errorDef)}}
                        </div>
                    </div>
               </div>`
})
export class ValidationMessagesComponent {

    _errorDefs: ErrorDef[] = [];

    @Input() formCtrl;
    @Input() patternMsg: string;
    @Input() set errorDefs(value: ErrorDef[]) {
        this._errorDefs = value;
    }
 validEmailRegEx:any=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    readonly standartErrorDefs: ErrorDef[] = [
        { error: 'required', localizationKey: 'ThisFieldIsRequired' } as ErrorDef,
        { error: 'emailrequired', localizationKey: 'EmailFieldIsRequired', errorProperty: 'requiredemail' } as ErrorDef,
        { error: 'Phonerequired', localizationKey: 'PhoneFieldIsRequired', errorProperty: 'requiredphone' } as ErrorDef,
        { error: 'courtRequired', localizationKey: 'CourtFieldIsRequired', errorProperty: 'requiredcourt' } as ErrorDef,
        { error: 'sectionRequired', localizationKey: 'SectionFieldIsRequired', errorProperty: 'requiredsection' } as ErrorDef,
        { error: 'maxValueExceeded', localizationKey: 'AuctionMaximumPeriodByDaysParamMax24HoursValidation' } as ErrorDef,
        { error: 'auctionMaximumPeriodByDaysNotAllowZero', localizationKey: 'AuctionMaximumPeriodByDaysParamGreaterThanZeroValidation' } as ErrorDef,
        { error: 'audienceAuctionMaximumByHoursNotAllowZero', localizationKey: 'AudienceAuctionMaximumByHoursParamGreaterThanZeroValidation' } as ErrorDef,
        { error: 'auctionMinutesCaseObserverEndAuctionParamValidationMessage', localizationKey: 'AuctionMinutesCaseObserverEndAuctionParamValidationMessage' } as ErrorDef,

        { error: 'MinimumAuctionPercentageAmountProofOfSolvencyBetween1and30', localizationKey: 'MinimumAuctionPercentageAmountProofOfSolvencyValidation' } as ErrorDef,
        { error: 'MaximumAuctionPercentageAmountProofOfSolvencyBetween1and30', localizationKey: 'MaximumAuctionPercentageAmountProofOfSolvencyValidation' } as ErrorDef,

        { error: 'minlength', localizationKey: 'PleaseEnterAtLeastNCharacter', errorProperty: 'requiredLength' } as ErrorDef,
        { error: 'maxlength', localizationKey: 'PleaseEnterNoMoreThanNCharacter', errorProperty: 'requiredLength' } as ErrorDef,
        { error: 'email', localizationKey: 'InvalidEmailAddress' } as ErrorDef,
        { error: 'pattern', localizationKey: 'InvalidPattern', errorProperty: 'requiredPattern' } as ErrorDef
    ];

    get errorDefsInternal(): ErrorDef[] {
        let standarts = _.filter(this.standartErrorDefs, (ed) => !_.find(this._errorDefs, (edC) => edC.error === ed.error));
        let all = <ErrorDef[]>_.concat(standarts, this._errorDefs);

        return all;
    }

    constructor(
        private appLocalizationService: AppLocalizationService
    ) { }

    getErrorDefinitionIsInValid(errorDef: ErrorDef): boolean {
        return !!this.formCtrl.errors[errorDef.error];
    }

    getErrorDefinitionMessage(errorDef: ErrorDef): string {

        let errorRequirement = this.formCtrl.errors[errorDef.error][errorDef.errorProperty];

        //Check if form Controle Name is Email   or phone number is required
        if(this.formCtrl.name=="EmailAddress" && this.formCtrl.errors.required==true){
            errorDef.localizationKey="EmailFieldIsRequired";
        }
        else if(this.formCtrl.name=="EmailAddress" && this.validEmailRegEx.test(this.formCtrl.errors.pattern.actualValue)==false ){
            errorDef.localizationKey="InvalidEmailAddress";
        }
        else if(this.formCtrl.name=="PhoneNumber" && this.formCtrl.errors.required==true ){
            errorDef.localizationKey="PhoneFieldIsRequired";
        }
        else if(this.formCtrl.name=="courts" && this.formCtrl.errors.required==true ){
            errorDef.localizationKey="CourtFieldIsRequired";
        }
        else if(this.formCtrl.name=="sections" && this.formCtrl.errors.required==true ){
            errorDef.localizationKey="SectionFieldIsRequired";
        }
        else if(this.formCtrl.name=="Value" && this.formCtrl.errors.maxValueExceeded==true ){
            errorDef.localizationKey="AuctionMaximumPeriodByDaysParamMax24HoursValidation";
        }
        else if(this.formCtrl.name=="Value" && this.formCtrl.errors.auctionMaximumPeriodByDaysNotAllowZero==true ){
            errorDef.localizationKey="AuctionMaximumPeriodByDaysParamGreaterThanZeroValidation";
        }
        else if(this.formCtrl.name=="Value" && this.formCtrl.errors.audienceAuctionMaximumByHoursNotAllowZero==true ){
            errorDef.localizationKey="AudienceAuctionMaximumByHoursParamGreaterThanZeroValidation";
        }
        else if(this.formCtrl.name=="Value" && this.formCtrl.errors.MaximumAuctionPercentageAmountProofOfSolvencyBetween1and30==true ){
            errorDef.localizationKey="MaximumAuctionPercentageAmountProofOfSolvencyValidation";
        }
        else if(this.formCtrl.name=="Value" && this.formCtrl.errors.MinimumAuctionPercentageAmountProofOfSolvencyBetween1and30==true ){
            errorDef.localizationKey="MinimumAuctionPercentageAmountProofOfSolvencyValidation";
        }
        else if(this.formCtrl.errors.pattern && this.patternMsg){
            errorDef.localizationKey=this.patternMsg;
        }
        return !!errorRequirement
            ? this.appLocalizationService.l(errorDef.localizationKey, errorRequirement)
            : this.appLocalizationService.l(errorDef.localizationKey);
    }
}

