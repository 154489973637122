import { Injectable } from '@angular/core';

import * as momentjs from 'moment';
const moment = momentjs;

import * as moment_ from 'moment-hijri';
const momentHijri = moment_;



@Injectable()
export class DateTimeUtlilityService {

  constructor() { }

    ToGregorian(hijriDate: string): string {

        const momentDate = momentHijri(hijriDate, 'iD/iM/iYY HH:mm:ss tt');

        let formattedDate = momentDate.locale('en').format('DD-MM-YYYY');
        return formattedDate;
    }

    ToGregorianWithTime(hijriDate: string): string {

        const momentDate = momentHijri(hijriDate, 'iD/iM/iYY HH:mm:ss tt');

        let formattedDate = momentDate.locale('en').format('DD-MM-YYYY HH:mm');
        return formattedDate;
    }

}
