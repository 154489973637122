import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[identityNumber]',
    providers: [{ provide: NG_VALIDATORS, useExisting: IdentityNumberValidatorDirective, multi: true }]

})
export class IdentityNumberValidatorDirective implements Validator {
    @Input('identityNumber') isSaudi: boolean = false;
    validate(control: AbstractControl): { [key: string]: any } | null {
        const input: string = control.value;
        let validationResult = null;
        if (input != null) {
            if (!/[0-9]/.test(input) || input.length != 10) {
                validationResult = validationResult || {};
                validationResult.isNotValid = true;
            }
            else if (!input.startsWith("1") && !input.startsWith("2")) {
                validationResult = validationResult || {};
                validationResult.isNotValid = true;
            }
            else {
                var sum = 0;
                for (let i = 0; i <= 8; i++) {
                    let currentDigit = Number(input.substr(i, 1));
                    if (i % 2 == 0) {
                        currentDigit *= 2;
                        if (currentDigit > 9)
                            currentDigit = 1 + (currentDigit % 10);
                    }
                    sum += currentDigit;
                }
                var lastDigit = sum % 10;
                if (lastDigit != 0)
                    lastDigit = 10 - lastDigit;

                if (lastDigit != Number(input.substr(9, 1))) {
                    validationResult = validationResult || {};
                    validationResult.isNotValid = true;
                }
                else if (this.isSaudi && !input.startsWith("1")) {
                    validationResult = validationResult || {};
                    validationResult.NotSaudi = true;
                }
            }
        }
        return validationResult;
    }
}
