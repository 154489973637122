﻿import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AuctionSessionsServiceProxy,
        ApiServiceProxies.SalesAgentAssistantsServiceProxy,
        ApiServiceProxies.SectionsServiceProxy,
        ApiServiceProxies.CourtsServiceProxy,
        ApiServiceProxies.AuctionSettingsServiceProxy,
        ApiServiceProxies.SalesAgentsServiceProxy,
        ApiServiceProxies.IntegrationServiceProxy,
        ApiServiceProxies.CitiesServiceProxy,
        ApiServiceProxies.AreasServiceProxy,
        ApiServiceProxies.AuctionsServiceProxy,
        ApiServiceProxies.AuctionImagesServiceProxy,
        ApiServiceProxies.AssetsServiceProxy,
        ApiServiceProxies.AssetTypesServiceProxy,
        ApiServiceProxies.CausesServiceProxy,
        ApiServiceProxies.InfazCauseServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.RealEStateServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.BiddingsServiceProxy,
        ApiServiceProxies.AuctionBrochureServiceProxy,
        ApiServiceProxies.AuctionBiddersServiceProxy,
        ApiServiceProxies.BiddersServiceProxy,
        ApiServiceProxies.SettlementReportServiceProxy,
        ApiServiceProxies.TermsandCondtionServiceProxy,
        ApiServiceProxies.AuctionBlackListServiceProxy,
        ApiServiceProxies.AuctionWhiteListServiceProxy,
        ApiServiceProxies.BillServiceProxy,
        ApiServiceProxies.CompanyRequestServiceProxy,
        ApiServiceProxies.PricingPercentageServiceProxy,
        ApiServiceProxies.AuctionRemarkServiceProxy,
        ApiServiceProxies.CancelledSettledActionServiceProxy,
        ApiServiceProxies.AuctionSettlementsServiceProxy,
        ApiServiceProxies.OperationManagerNotificationServiceProxy,
        ApiServiceProxies.GetRealestateDetailsByAuctionIdServiceProxy,
        ApiServiceProxies.GetRealestateAddtionalAssetDetailsByIdProxyService,
        ApiServiceProxies.GetVehicleDetailsByIdProxyService,
        ApiServiceProxies.AuctionAuditLogServiceProxy,
        ApiServiceProxies.ImagesUploaderServiceProxy,
        ApiServiceProxies.GetLocationOfSharingGoogleMapUrlServiceProxy,
        ApiServiceProxies.AgreementServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
