﻿
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {

        if(this._appSessionService.tenancyName == 'infath'){
            return new AppMenu('MainMenu', 'MainMenu', [
                new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
                new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
                // new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
                 new AppMenuItem('فواتير الترسية', 'GET:FinancialOfficer', 'flaticon-more', '/app/main/mazadAdmin/settlement-bills'),
                 new AppMenuItem('الفواتير المنشأة خارج المنصة', 'GET:FinancialOfficer', 'flaticon-more', '/app/main/mazadAdmin/bills-paid-manually'),
                 new AppMenuItem('الفواتير المنشأة خارج المنصة', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/bills-paid-manually'),
                 new AppMenuItem('بيانات الشركاء', 'GET:FinancialOfficer', 'flaticon-more', '/app/main/mazadAdmin/tenants-info'),
    
                 new AppMenuItem('AuctionSettings', 'Pages.AuctionSettings', 'flaticon-more', '/app/main/mazadLookup/auctionSettings'),
                 new AppMenuItem('TermsCondtionsBidder', 'Pages.TermsCondtions', 'flaticon-more', '/app/main/termscondetionsBidder'),
                 new AppMenuItem('TermsCondtionsSalesAgent', 'Pages.TermsCondtions', 'flaticon-more', '/app/main/termscondetionsSalesAgent'),
                 new AppMenuItem('TermsCondtionsObserver', 'Pages.TermsCondtions', 'flaticon-more', '/app/main/termscondetionsObserver'),
                 new AppMenuItem('AssetTypes', 'Pages.AssetTypes', 'flaticon-more', '/app/main/mazadAdmin/assettypes'),
                 new AppMenuItem('RealEstatTypes', 'Pages.RealStateTypes', 'flaticon-more', '/app/main/mazadAdmin/realestatetypes'),
                new AppMenuItem('Courts', 'Pages.Courts', 'flaticon-more', '/app/main/mazadLookup/Courts'),
    
                 new AppMenuItem('Sections', 'Pages.Sections', 'flaticon-more', '/app/main/mazadLookup/sections'),
                // new AppMenuItem('Lookup', '', 'flaticon-interface-8', '',[
                //     new AppMenuItem('Areas', 'Pages.Areas', 'flaticon-more', '/app/main/lookup/areas'),
                //     new AppMenuItem('Cities', 'Pages.Cities', 'flaticon-more', '/app/main/lookup/cities')
                // ]),
                new AppMenuItem('SalesAgents', 'Pages.SalesAgents', 'flaticon-more', '/app/main/salesAgentsAdministration/salesAgents'),
                new AppMenuItem('SalesAgentAssistants', 'Pages.SalesAgentAssistants', 'flaticon-users', '/app/main/salesAgentsAdministration/salesAgentAssistants'),
                // new AppMenuItem('SalesAgentsAdministration', '', 'flaticon-interface-8', '',[
                //     new AppMenuItem('SalesAgents', 'Pages.SalesAgents', 'flaticon-more', '/app/main/salesAgentsAdministration/salesAgents'),
                // ]),
    
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
    
                // new AppMenuItem('Causes', 'Pages.Causes', 'flaticon-more', '/app/main/mazadAdmin/causes'),
                new AppMenuItem('Causes', 'Pages.Causes.Infath', 'flaticon-users', '/app/main/mazadAdmin/infathcauses'),
    
                //new AppMenuItem('Causes', 'Pages.InfathGeneralUsers', 'flaticon-more', '/app/main/mazadAdmin/infathcauses'),
    
                new AppMenuItem('Assets', 'Pages.Assets', 'flaticon-more', '/app/main/mazadAdmin/assets'),
                new AppMenuItem('Assets', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/ops-manager-assets'),
    
    
                new AppMenuItem('AttendeeBidderManagement', 'Pages.AttendeeBidders', 'flaticon-more', '/app/main/mazadAdmin/attendeeBidders'),
    
                new AppMenuItem('Auctions', 'Pages.Auctions', 'flaticon-more', '/app/main/mazadAdmin/auctions'),
                new AppMenuItem('RunningAuctions', 'Pages.Running.Auctions', 'flaticon-more', '/app/main/mazadAdmin/runningauctions'),
                new AppMenuItem('AuctionsHistory', 'Mazad.Roles.Auctions.History', 'flaticon-folder-1', '/app/main/mazadAdmin/historicalauctions'),
                new AppMenuItem('InfathGeneralUsers', 'Pages.InfathGeneralUsers', 'flaticon-users', '/app/main/mazadAdmin/generalusers'),
                
                // new AppMenuItem('SalesAgentAssistants', 'Pages.SalesAgentAssistants', 'flaticon-more', '/app/main/salesAgentsAdministration/salesAgentAssistants'),
                 new AppMenuItem('ObserverAdministration', 'notAccessed', 'flaticon-interface-8', '', [
                    // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles.admin', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    // new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                    // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                    // new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                    new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                    // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                     new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                    new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
                ]),
    
                new AppMenuItem('ادارة المزايدين', 'Pages.Bidders.Lists', 'flaticon-users', '' , [
                    new AppMenuItem('BlackList', 'Pages.Bidders.Blacklist', 'flaticon-circle', '/app/main/mazadAdmin/blacklist'),
                    new AppMenuItem('WhiteList', 'Pages.Bidders.Whitelist', 'flaticon-menu-2', '/app/main/mazadAdmin/whitelist'),
                ]),
                new AppMenuItem('UserManagement', 'SystemAdministration', 'flaticon-users', '/app/admin/users'),
    
                new AppMenuItem('CompanyRequests', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/company-requests'),
                new AppMenuItem('CanceledSettledAuction', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/canceled-settled-auctions'),
                new AppMenuItem('OMAuctions', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/operations-managment'),
                new AppMenuItem('AuctionsSettle', 'Pages.AuctionSettlements', 'flaticon-more', '/app/main/mazadAdmin/pending-approval'),
                new AppMenuItem('OpsManagerNotification', 'GET:OperationsManagementOfficer', 'flaticon-alert', '/app/main/mazadAdmin/ops-manager-notifications'),
                new AppMenuItem('AuctionLogs', 'GET:OperationsManagementOfficer', 'flaticon-alert', '/app/main/mazadAdmin/auction-logs'),
    
            ]);


        } else {
            return new AppMenu('MainMenu', 'MainMenu', [
                new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
                new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
                // new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
                 new AppMenuItem('فواتير الترسية', 'GET:FinancialOfficer', 'flaticon-more', '/app/main/mazadAdmin/settlement-bills'),
                 new AppMenuItem('الفواتير المنشأة خارج المنصة', 'GET:FinancialOfficer', 'flaticon-more', '/app/main/mazadAdmin/bills-paid-manually'),
                 new AppMenuItem('الفواتير المنشأة خارج المنصة', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/bills-paid-manually'),
                 new AppMenuItem('بيانات الشركاء', 'GET:FinancialOfficer', 'flaticon-more', '/app/main/mazadAdmin/tenants-info'),
                 new AppMenuItem('فواتير الترسية', 'Mazad.Roles.Observer', 'flaticon-more', '/app/main/mazadAdmin/settlement-bills'),
                 new AppMenuItem('فواتير الترسية', 'Mazad.Roles.Sales.Agent', 'flaticon-more', '/app/main/mazadAdmin/settlement-bills'),
                 new AppMenuItem('فواتير الترسية', 'Mazad.Roles.Sales.Agent.Assistant', 'flaticon-more', '/app/main/mazadAdmin/settlement-bills'),
    
                 new AppMenuItem('AuctionSettings', 'Pages.AuctionSettings', 'flaticon-more', '/app/main/mazadLookup/auctionSettings'),
                 new AppMenuItem('TermsCondtionsBidder', 'Pages.TermsCondtions', 'flaticon-more', '/app/main/termscondetionsBidder'),
                 new AppMenuItem('TermsCondtionsSalesAgent', 'Pages.TermsCondtions', 'flaticon-more', '/app/main/termscondetionsSalesAgent'),
                 new AppMenuItem('TermsCondtionsObserver', 'Pages.TermsCondtions', 'flaticon-more', '/app/main/termscondetionsObserver'),
                 new AppMenuItem('AssetTypes', 'Pages.AssetTypes', 'flaticon-more', '/app/main/mazadAdmin/assettypes'),
                 new AppMenuItem('RealEstatTypes', 'Pages.RealStateTypes', 'flaticon-more', '/app/main/mazadAdmin/realestatetypes'),
                new AppMenuItem('Courts', 'Pages.Courts', 'flaticon-more', '/app/main/mazadLookup/Courts'),
    
                 new AppMenuItem('Sections', 'Pages.Sections', 'flaticon-more', '/app/main/mazadLookup/sections'),
                // new AppMenuItem('Lookup', '', 'flaticon-interface-8', '',[
                //     new AppMenuItem('Areas', 'Pages.Areas', 'flaticon-more', '/app/main/lookup/areas'),
                //     new AppMenuItem('Cities', 'Pages.Cities', 'flaticon-more', '/app/main/lookup/cities')
                // ]),
                new AppMenuItem('SalesAgents', 'Pages.SalesAgents', 'flaticon-more', '/app/main/salesAgentsAdministration/salesAgents'),
                new AppMenuItem('SalesAgentAssistants', 'Pages.SalesAgentAssistants', 'flaticon-users', '/app/main/salesAgentsAdministration/salesAgentAssistants'),
                // new AppMenuItem('SalesAgentsAdministration', '', 'flaticon-interface-8', '',[
                //     new AppMenuItem('SalesAgents', 'Pages.SalesAgents', 'flaticon-more', '/app/main/salesAgentsAdministration/salesAgents'),
                // ]),
    
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
    
                new AppMenuItem('Causes', 'Pages.Causes', 'flaticon-more', '/app/main/mazadAdmin/causes'),
    
                //new AppMenuItem('Causes', 'Pages.InfathGeneralUsers', 'flaticon-more', '/app/main/mazadAdmin/infathcauses'),
    
                new AppMenuItem('Assets', 'Pages.Assets', 'flaticon-more', '/app/main/mazadAdmin/assets'),
                new AppMenuItem('Assets', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/ops-manager-assets'),
    
    
                new AppMenuItem('AttendeeBidderManagement', 'Pages.AttendeeBidders', 'flaticon-more', '/app/main/mazadAdmin/attendeeBidders'),
    
                new AppMenuItem('Auctions', 'Pages.Auctions', 'flaticon-more', '/app/main/mazadAdmin/auctions'),
                new AppMenuItem('RunningAuctions', 'Pages.Running.Auctions', 'flaticon-more', '/app/main/mazadAdmin/runningauctions'),
                new AppMenuItem('AuctionsHistory', 'Mazad.Roles.Auctions.History', 'flaticon-folder-1', '/app/main/mazadAdmin/historicalauctions'),
                new AppMenuItem('InfathGeneralUsers', 'Pages.InfathGeneralUsers', 'flaticon-users', '/app/main/mazadAdmin/generalusers'),
                
                // new AppMenuItem('SalesAgentAssistants', 'Pages.SalesAgentAssistants', 'flaticon-more', '/app/main/salesAgentsAdministration/salesAgentAssistants'),
                 new AppMenuItem('ObserverAdministration', 'notAccessed', 'flaticon-interface-8', '', [
                    // new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles.admin', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    // new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                    // new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                    // new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                    new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                    // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                     new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                    new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
                ]),
                // new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
    
                new AppMenuItem('AuctionSessions', 'Pages.AuctionSessions', 'flaticon-more', '/app/main/session/auctionSessions'),
    
                new AppMenuItem('ادارة المزايدين', 'Pages.Bidders.Lists', 'flaticon-users', '' , [
                    new AppMenuItem('BlackList', 'Pages.Bidders.Blacklist', 'flaticon-circle', '/app/main/mazadAdmin/blacklist'),
                    new AppMenuItem('WhiteList', 'Pages.Bidders.Whitelist', 'flaticon-menu-2', '/app/main/mazadAdmin/whitelist'),
                ]),
                new AppMenuItem('UserManagement', 'SystemAdministration', 'flaticon-users', '/app/admin/users'),
    
                new AppMenuItem('CompanyRequests', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/company-requests'),
                new AppMenuItem('CanceledSettledAuction', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/canceled-settled-auctions'),
                new AppMenuItem('الشروط والأحكام', 'Mazad.Roles.Observer', 'flaticon-more', '/app/main/mazadAdmin/terms/isMenu'),
                new AppMenuItem('الشروط والأحكام', 'Mazad.Roles.Sales.Agent.Assistant', 'flaticon-more', '/app/main/mazadAdmin/terms/isMenu'),
                new AppMenuItem('الشروط والأحكام', 'Mazad.Roles.Sales.Agent', 'flaticon-more', '/app/main/mazadAdmin/terms/isMenu'),
                new AppMenuItem('OMAuctions', 'GET:OperationsManagementOfficer', 'flaticon-more', '/app/main/mazadAdmin/operations-managment'),
                new AppMenuItem('AuctionsSettle', 'Pages.AuctionSettlements', 'flaticon-more', '/app/main/mazadAdmin/pending-approval'),
                new AppMenuItem('OpsManagerNotification', 'GET:OperationsManagementOfficer', 'flaticon-alert', '/app/main/mazadAdmin/ops-manager-notifications'),
                new AppMenuItem('OpsManagerNotification', 'Mazad.Roles.Observer', 'flaticon-alert', '/app/main/mazadAdmin/admin-notifications'),
                new AppMenuItem('OpsManagerNotification', 'Mazad.Roles.Sales.Agent', 'flaticon-alert', '/app/main/mazadAdmin/admin-notifications'),
                new AppMenuItem('OpsManagerNotification', 'Mazad.Roles.Sales.Agent.Assistant', 'flaticon-alert', '/app/main/mazadAdmin/admin-notifications'),
                new AppMenuItem('AuctionLogs', 'GET:OperationsManagementOfficer', 'flaticon-alert', '/app/main/mazadAdmin/auction-logs'),
    
            ]);

        }

  
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }


        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
