import { ApmService } from '@elastic/apm-rum-angular';
import { Component } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-root',
    template:  `<router-outlet></router-outlet>`
})
export class RootComponent {

    constructor(private service: ApmService, private _sessionService: AppSessionService) {
    }

    ngOnInit(): void {

        if (AppConsts.EnableRum == 'true') {

        // Agent API is exposed through this apm instance
            const apm = this.service.init({
                serviceName: AppConsts.serviceName,
                serverUrl: AppConsts.elasticRumUrl,
                serviceVersion:AppConsts.serviceVersion,
                active : AppConsts.active,
                instrument : AppConsts.instrument,
                disableInstrumentations : AppConsts.disableInstrumentations,
                environment : AppConsts.environment,
                logLevel : AppConsts.logLevel,
                apiVersion : AppConsts.apiVersion,
                breakdownMetrics : AppConsts.breakdownMetrics,
                flushInterval : AppConsts.flushInterval,
                pageLoadTraceId : AppConsts.pageLoadTraceId,
                pageLoadSampled : AppConsts.pageLoadSampled,
                pageLoadSpanId : AppConsts.pageLoadSpanId,
                pageLoadTransactionName : AppConsts.pageLoadTransactionName,
                distributedTracing : AppConsts.distributedTracing,
                distributedTracingOrigins : AppConsts.distributedTracingOrigins,
                propagateTracestate : AppConsts.propagateTracestate
            })
            apm.setUserContext({
            'username': this._sessionService.user ? this._sessionService.user.name: '',
            'id':  this._sessionService.userId,
            'email': this._sessionService.user ? this._sessionService.user.emailAddress: ''
            })
      }
    }

}
