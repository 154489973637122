import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CaptchaService {


    private captchaChange$: Subject<null>;


    constructor(injector: Injector) {
        this.captchaChange$ = new Subject<null>();
    }

    getCaptchaChange(){
        return this.captchaChange$;
    }

    showInvalidCaptcha(){
        abp.message.warn(
            'الرجاء ادخال رمز التحقق بطريقة صحيحة',
            'رمز التحقق خطأ'
        ).then(() => { return this.captchaChange$.next(); });
    }

    validate(model) : boolean{

        if (model.captchaData.captcha == '' || model.captchaData.captcha == null) {
             abp.message.error("ادخل رمز التحقق")
             return false;
        }
        return true;
    }

}
